import * as MachineActions from '../../../../actions/customer-machine';
import { Box, Grid } from '@material-ui/core';
import { CustomerMachineStatisticsWithdrawals } from './machine-withdrawals';
import { CustomerMachinesStatisticsWithdrawals } from './withdrawals';
import { CustomerRouteParams } from 'src/pages/customer/models';
import { FormattedMessage, useIntl } from 'react-intl';
import { MachineInput } from '../machine-input';
import { RootState } from 'src/reducers';
import { WikusButton } from '@wikus-ui/components/core/button';
import { WikusPanel } from '@wikus-ui/components/core/card';
import { filter } from 'lodash';
import { useActions } from '../../../../actions';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const CustomerMachinesDashboard = () => {
  const intl = useIntl();
  const { id: customerId } = useParams<CustomerRouteParams>();
  const [machineIds, setMachineIds] = useState<string[]>([]);
  const [months, setMonths] = useState(6);
  const machines = useSelector((state: RootState) => state.customerMachines);
  const customerMachineActions: typeof MachineActions =
    useActions(MachineActions);

  useEffect(() => {
    if (customerId) {
      customerMachineActions.fetchMachines(customerId);
    }
  }, [customerId]);

  const onChange = (_: any, newValue: any) => {
    setMachineIds(newValue.map((a: any) => a.id));
  };

  const getFilteredMachines = useCallback(() => {
    return filter(
      machines,
      a => !machineIds?.includes(a.id)
    );
  }, [machineIds, machines]);

  const getCurrentMachine = useCallback((machineId: string) => {
    return machines.find(a => a.id === machineId);
  }, [machines]);

  return (
    <Grid container spacing={3}>
      <Grid item xs>
        <WikusPanel
          title={intl.formatMessage({ id: 'customers.filter' })}
        >
          <MachineInput options={getFilteredMachines()} onChange={onChange} />
          <Box mt={2}>
            <Grid container spacing={2}>
              <Grid item>
                <WikusButton
                  color="primary"
                  variant={months === 60 ? 'contained' : 'outlined'}
                  onClick={() => setMonths(60)}>
                  <FormattedMessage id="months.60" />
                </WikusButton>
              </Grid>
              <Grid item>
                <WikusButton
                  color="primary"
                  variant={months === 24 ? 'contained' : 'outlined'}
                  onClick={() => setMonths(24)}>
                  <FormattedMessage id="months.24" />
                </WikusButton>
              </Grid>
              <Grid item>
                <WikusButton
                  color="primary"
                  variant={months === 12 ? 'contained' : 'outlined'}
                  onClick={() => setMonths(12)}>
                  <FormattedMessage id="months.12" />
                </WikusButton>
              </Grid>
              <Grid item>
                <WikusButton
                  color="primary"
                  variant={months === 6 ? 'contained' : 'outlined'}
                  onClick={() => setMonths(6)}>
                  <FormattedMessage id="months.6" />
                </WikusButton>
              </Grid>
              <Grid item>
                <WikusButton
                  color="primary"
                  variant={months === 1 ? 'contained' : 'outlined'}
                  onClick={() => setMonths(1)}>
                  <FormattedMessage id="months.1" />
                </WikusButton>
              </Grid>
            </Grid>
          </Box>
        </WikusPanel>
      </Grid>
      <Grid item container spacing={3} direction='row'>
        <Grid item xs={12} md={4}>
          <WikusPanel
            title={intl.formatMessage({ id: 'customers.machines.withdrawals' })}
          >
            <CustomerMachinesStatisticsWithdrawals
              machineIds={machineIds}
              months={months}
            />
          </WikusPanel>
        </Grid>
        {machineIds.map((machineId) => (<Grid item xs={12} md={8}>
          <WikusPanel title={getCurrentMachine(machineId)?.name}>
            <CustomerMachineStatisticsWithdrawals
              machineId={machineId}
              months={months}
            />
          </WikusPanel>
        </Grid>))}
      </Grid>
    </Grid>
  );
};
