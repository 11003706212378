import { FilterItem } from './misc';
import { RequestPayload, ResponsePayload } from './request';

export interface CustomerQueryParams {
  take?: number;
  skip?: number;
}

export enum CustomerActions {
  FETCH_CUSTOMERS = 'FETCH_CUSTOMERS',
  FETCH_CUSTOMERS_SUCCESS = 'FETCH_CUSTOMERS_SUCCESS',
  FETCH_MORE_CUSTOMERS = 'FETCH_MORE_CUSTOMERS',
  FETCH_MORE_CUSTOMERS_SUCCESS = 'FETCH_MORE_CUSTOMERS_SUCCESS',
  FETCH_CUSTOMERS_FAIL = 'FETCH_CUSTOMERS_FAIL',
  FETCH_DETAILS = 'FETCH_DETAILS',
  FETCH_DETAILS_SUCCESS = 'FETCH_DETAILS_SUCCESS',
  SELECT_CUSTOMER = 'SELECT_CUSTOMER',

  FETCH_CUSTOMERS_FILTER = 'FETCH_CUSTOMERS_FILTER',
  FETCH_CUSTOMERS_FILTER_SUCCESS = 'FETCH_CUSTOMERS_FILTER_SUCCESS'
}

interface CustomerActionType<T, P> {
  type: T;
  payload: P;
  asyncDispatch?: any;
}

export interface Customer {
  id: string;
  company: number;
  customerNumber: string;
  customerName: string;

  addressType: number;
  addressNumber: string;

  division: string;
  isActive: boolean;

  settings?: {
    featureMachines?: boolean;
  }
}

export interface FetchSuccessCustomerPayload {
  items: Customer[];
  count: number;
}

export interface CustomersFilter {
  name: FilterItem[];
}

export type CustomerAction =
  | CustomerActionType<typeof CustomerActions.FETCH_CUSTOMERS, RequestPayload>
  | CustomerActionType<
    typeof CustomerActions.FETCH_CUSTOMERS_SUCCESS,
    ResponsePayload<FetchSuccessCustomerPayload>
  >
  | CustomerActionType<
    typeof CustomerActions.FETCH_MORE_CUSTOMERS,
    RequestPayload
  >
  | CustomerActionType<
    typeof CustomerActions.FETCH_MORE_CUSTOMERS_SUCCESS,
    ResponsePayload<FetchSuccessCustomerPayload>
  >
  | CustomerActionType<typeof CustomerActions.FETCH_CUSTOMERS_FAIL, null>
  | CustomerActionType<typeof CustomerActions.FETCH_DETAILS, RequestPayload>
  | CustomerActionType<
    typeof CustomerActions.FETCH_DETAILS_SUCCESS,
    ResponsePayload<Customer>
  >
  | CustomerActionType<typeof CustomerActions.SELECT_CUSTOMER, Customer>
  | CustomerActionType<
    typeof CustomerActions.FETCH_CUSTOMERS_FILTER,
    RequestPayload
  >
  | CustomerActionType<
    typeof CustomerActions.FETCH_CUSTOMERS_FILTER_SUCCESS,
    ResponsePayload<CustomersFilter>
  >;
