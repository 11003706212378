import { Button, Fade, Grid, Menu, MenuItem, Theme, withStyles } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import { NavigationUrl } from './hero';
import { useMemo, useState } from 'react';

const styles: any = (theme: Theme) => {
  return {
    link: {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.primary.main,
      fontSize: theme.typography.fontSize,
    },
  }
}

const WikusHeroMenuItem = withStyles(styles)(({ classes, items, url, label }: NavigationUrl & { classes: { [key: string]: any } }) => {
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const open = Boolean(anchorEl);

  // Callback to check if the current URL starts with the provided url prop
  const isCurrentUrl = useMemo(() => {
    return location.pathname.startsWith(url);
  }, [location.pathname, url]);

  const handleOpen = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return items?.length ? (
    <>
      <Grid item>
        <Button
          color="primary"
          variant={isCurrentUrl ? 'contained' : undefined}
          onMouseEnter={handleOpen}
          onClick={handleOpen}>{label}</Button>
      </Grid>
      <Menu
        id={`fade-menu-${encodeURIComponent(url)}`}
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        MenuListProps={{
          onMouseLeave: handleClose,
        }}

      >
        {items.map((nav, i) => (
          <MenuItem
            key={i}
            className={classes.link}
            component={Link}
            to={nav.url}
            onClick={handleClose}>
            {nav.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  ) : (
    <Grid item>
      <Link to={url}>
        <Button color="primary">{label}</Button>
      </Link>
    </Grid>
  )
});

// set for storybook to render html preview correctly
WikusHeroMenuItem.displayName = 'WikusHeroMenuItem';

export { WikusHeroMenuItem };