import { WikusPanel } from '@wikus-ui/components/core/card';
import { useIntl } from 'react-intl';

import * as MachineActions from '../../../actions/customer-machine';
import {
  CreateMachineDto,
  MachineActions as MachineActionsModel
} from '../../../model/customer-machine';
import { useActions } from '../../../actions';

import { CustomerRouteParams } from '../../../pages/customer/models';
import { MachineForm } from './machine-form';
import { useParams } from 'react-router-dom';

type TCreateMachineFormProps = {
  onCreate?: () => void;
};

export const CreateMachineForm = ({
  onCreate,
}: TCreateMachineFormProps) => {
  const { id: customerId } = useParams<CustomerRouteParams>();

  const intl = useIntl();
  const customerMachineActions: typeof MachineActions = useActions(
    MachineActions
  );

  const onSubmit = async (machine: CreateMachineDto) => {
    const resp = await customerMachineActions.createMachine(customerId, machine);
    const isSuccess =
      resp.type === MachineActionsModel.CREATE_CUSTOMER_MACHINE_SUCCESS;

    if (isSuccess) {
      onCreate && onCreate();
    }

    return isSuccess;
  };

  return (
    <WikusPanel
      title={intl.formatMessage({ id: 'customer-machine.create' })}
    >
      <MachineForm onSubmit={onSubmit} />
    </WikusPanel>
  );
};
