import { OnSubmitHandler, useInputGroup } from '@wikus-ui/hooks/useInputGroup';
import { WikusButton, WikusButtonRow } from '@wikus-ui/components/core/button';
import { WikusInputRow, WikusTextField } from '@wikus-ui/components/core/input';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';

import {
  CreateMachineDto,
  Machine
} from '../../../model/customer-machine';

import { CustomerRouteParams } from '../../../pages/customer/models';
import { useParams } from 'react-router-dom';

type TMachineFormProps = {
  machine?: Machine;
  onSubmit: (machine: CreateMachineDto) => Promise<boolean>;
  onAbort?: () => void;
};

export const MachineForm = ({
  machine,
  onSubmit,
  onAbort,
}: TMachineFormProps) => {
  const { id: customerId } = useParams<CustomerRouteParams>();
  const intl = useIntl();
  const isCreateMachine = !machine;
  const UpsertMachineInputGroup = isCreateMachine ? `create-customer-machine-${customerId}` : `customer-machine-${machine.id}`;

  const _onSubmit: OnSubmitHandler = async () => {
    if (!isValid) {
      return;
    }

    const isSuccess = await onSubmit(
      value as CreateMachineDto
    );

    if (isSuccess && isCreateMachine) {
      setGroup({
        submitted: false,
        value: {
          name: '',
          specification: '',
        },
      });
    }

    return isSuccess;
  };

  const { value, isValid, onSubmit: onSubmitInternal, createInputProps, isSubmitting, setGroup } =
    useInputGroup(UpsertMachineInputGroup, _onSubmit, 'customer-machine');

  useEffect(() => {
    if (isCreateMachine) {
      setGroup({
        submitted: false,
        value: {
          name: '',
          specification: '',
        },
      });

      return;
    }

    setGroup({
      submitted: false,
      value: {
        name: machine.name,
        specification: '',
      },
    });
  }, []);

  return (
    <form onSubmit={onSubmitInternal}>
      <WikusInputRow>
        <WikusTextField
          {...createInputProps('name')}
        />
      </WikusInputRow>
      <WikusInputRow>
        <WikusTextField
          {...createInputProps('specification')}
          multiline={true}
          minRows={4}
          maxRows={4}
        />
      </WikusInputRow>
      <WikusButtonRow>
        <WikusButton type="submit" color="primary" disabled={isSubmitting || isValid === undefined}>
          {intl.formatMessage({
            id: 'customer-machine.create.submit',
          })}
        </WikusButton>
        {
          !isCreateMachine && !!onAbort
            ? (
              <WikusButton
                onClick={onAbort}
                color="primary"
                variant="outlined"
                disabled={isSubmitting}
              >
                {intl.formatMessage({
                  id: 'customer-machine.abort',
                })}
              </WikusButton>
            )
            : undefined
        }
      </WikusButtonRow>
    </form>
  );
};
